import "./stylesheets/styles.scss";
import "@fortawesome/fontawesome-free";
//import "./node_modules/jquery.easing/jquery.easing.js";
//import "./node_modules/jquery.easing/jquery.easing.min.js";


/* Text effect */

var TxtRotate = function(el, toRotate, period) {
    this.toRotate = toRotate;
    this.el = el;
    this.loopNum = 0;
    this.period = parseInt(period, 10) || 1000;
    this.txt = '';
    this.tick();
    this.isDeleting = false;
  };
  
  TxtRotate.prototype.tick = function() {
    var i = this.loopNum % this.toRotate.length;
    var fullTxt = this.toRotate[i];
  
    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1);
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1);
    }
  
    this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';
  
    var that = this;
    var delta = 300 - Math.random() * 100;
  
    if (this.isDeleting) { delta /= 2; }
  
    if (!this.isDeleting && this.txt === fullTxt) {
      delta = this.period;
      this.isDeleting = true;
    } else if (this.isDeleting && this.txt === "") {
      this.isDeleting = false;
      this.loopNum++;
      delta = 500;
    }
  
    setTimeout(function() {
      that.tick();
    }, delta);
  };
  
  window.onload = function() {
    var elements = document.getElementsByClassName('txt-rotate');
    for (var i=0; i<elements.length; i++) {
      var toRotate = elements[i].getAttribute('data-rotate');
      var period = elements[i].getAttribute('data-period');
      if (toRotate) {
        new TxtRotate(elements[i], JSON.parse(toRotate), period);
      }
    }
    // Inject CSS
    var css = document.createElement("style");
    css.type = "text/css";
    css.innerHTML = ".txt-rotate > .wrap { border-right: 0.08em solid #666 }";
    document.body.appendChild(css);
  };

/* Keeping the footer appearing under the last separator */

function footerBehind(){
  var footerHeight = ($('.contact').outerHeight())-$('.contact').outerHeight()*0.12;
  $('.location').css('margin-bottom', footerHeight);
}

$(document).ready(footerBehind);
$(window).resize(footerBehind);

//var jQuery = require('jquery');
//require('jquery.easing')(jQuery);

/* Smooth scrolling implementation */

$(document).ready(function(){
  // Add smooth scrolling to all links
  $("a").on('click', function(event) {

    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      var hash = this.hash;
      var isMobile = window.matchMedia("(max-width: 768px)");

      var animateWithScrollOffset = function(offset){
        $('html, body').animate({
          scrollTop: $(hash).offset().top-offset
        }, 1100, "easeInOutExpo", function(){
          window.location.hash = hash;
        });
      }
      

      /* offsets set manually and specifically for the current state of the page */
      
      if(isMobile.matches){
        if (hash == "#contact"){
          animateWithScrollOffset(638);
        }
        else if (hash == "#who"){
          animateWithScrollOffset(40);
        }
        else{
          animateWithScrollOffset(0);
        }
      }
      else{
        if (hash == "#contact"){
          animateWithScrollOffset(window.outerHeight*0.87);
        }
        else if (hash == "#who"){
          animateWithScrollOffset(-60);
        }
        else{
          animateWithScrollOffset(-50);
        }
      }
    } 
  });
});

/* Impletemented functions for smooth scrolling - failed import */

jQuery.easing['jswing'] = jQuery.easing['swing'];
jQuery.extend( jQuery.easing,
{
    def: 'easeOutQuad',
    swing: function (x, t, b, c, d) {
        //alert(jQuery.easing.default);
        return jQuery.easing[jQuery.easing.def](x, t, b, c, d);
    },
    
    easeInOutSine: function (x, t, b, c, d) {
        return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
    },
    easeInOutExpo: function (x, t, b, c, d) {
        if (t==0) return b;
        if (t==d) return b+c;
        if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
        return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
    },
    easeInOutCirc: function (x, t, b, c, d) {
        if ((t/=d/2) < 1) return -c/2 * (Math.sqrt(1 - t*t) - 1) + b;
        return c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b;
    },
  
    easeInOutBack: function (x, t, b, c, d, s) {
        if (s == undefined) s = 1.70158; 
        if ((t/=d/2) < 1) return c/2*(t*t*(((s*=(1.525))+1)*t - s)) + b;
        return c/2*((t-=2)*t*(((s*=(1.525))+1)*t + s) + 2) + b;
    }
});

/* Sets changes to the overlay menu on mobile and hides the overflow scroll */

window.changeToMobileMenu = function(x){
  if (document.body.style.overflow == "hidden")
    document.body.style.overflow = "visible";
  else
    document.body.style.overflow = "hidden";

  x.classList.toggle("change");
  document.getElementById("overlayMenu").classList.toggle("open");
};
